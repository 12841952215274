/* page sidebar css */

.zl_page_sidebar{
    background: #252F47;
    max-width: 250px;
    width: 100%;
    min-height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    max-height: 469px;
    overflow-y: auto;
    transition: all 0.3s ease-in-out;
    z-index: 99999999;
}

.zl_page_sidebar::-webkit-scrollbar {
  width: 0;
}

.zl_page_sidebar .zl_page_sidebar_content{
    min-height: 100vh;
    padding-bottom: 100px;
    position: relative;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_logo a{
    max-width: 152px;
    display: block;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_logo{
    padding: 23px 25px 28px;
    position: relative;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_logo .zl_page_sidebar_toggle_btn{
    top: 15px;
    right: 20px;
    left: auto;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link{
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #828CAE;
    padding: 17px 25px 17px 35px;
    text-decoration: none;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link svg{
    margin-right: 32px;
}

.zl_page_sidebar[title='dashboard'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='dashboard'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='portfolio'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='portfolio'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='wallets'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='wallets'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='history'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='history'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='securebackup'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='securebackup'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='settings'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='settings'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='asset'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='asset'] .zl_page_sidebar_link::before,
.zl_page_sidebar[title='accountsupport'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='accountsupport'] .zl_page_sidebar_link::before{
    content: "";
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #53B9EA;
    box-shadow: 0px 0px 5px #1F1C45;
}

.zl_page_sidebar[title='dashboard'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='dashboard'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='portfolio'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='portfolio'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='wallets'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='wallets'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='history'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='history'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='securebackup'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='securebackup'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='asset'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='asset'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='settings'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='settings'] .zl_page_sidebar_link::after,
.zl_page_sidebar[title='accountsupport'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='accountsupport'] .zl_page_sidebar_link::after{
    content: "";
    position: absolute;
    left: -17px;
    top: 50%;
    transform: translateY(-50%);
    width: 34px;
    height: 34px;
    border-radius: 34px;
    background: radial-gradient(43.97% 43.97% at 50% 51.18%, rgba(0, 196, 255, 0.0001) 0%, rgba(50, 196, 246, 0.264719) 35.94%, rgba(83, 185, 234, 0.0001) 100%);
}

.zl_page_sidebar[title='dashboard'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='dashboard'] .zl_page_sidebar_link,
.zl_page_sidebar[title='portfolio'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='portfolio'] .zl_page_sidebar_link,
.zl_page_sidebar[title='wallets'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='wallets'] .zl_page_sidebar_link,
.zl_page_sidebar[title='history'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='history'] .zl_page_sidebar_link,
.zl_page_sidebar[title='securebackup'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='securebackup'] .zl_page_sidebar_link,
.zl_page_sidebar[title='asset'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='asset'] .zl_page_sidebar_link,
.zl_page_sidebar[title='settings'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='settings'] .zl_page_sidebar_link,
.zl_page_sidebar[title='accountsupport'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='accountsupport'] .zl_page_sidebar_link{
    color: #53B9EA;
}

.zl_page_sidebar[title='dashboard'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='dashboard'] .zl_page_sidebar_link svg rect,
.zl_page_sidebar[title='accountsupport'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='accountsupport'] .zl_page_sidebar_link svg path,
.zl_page_sidebar[title='securebackup'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='securebackup'] .zl_page_sidebar_link svg path,
.zl_page_sidebar[title='asset'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='asset'] .zl_page_sidebar_link svg path,
.zl_page_sidebar[title='history'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='history'] .zl_page_sidebar_link svg path,
.zl_page_sidebar[title='asset'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='asset'] .zl_page_sidebar_link svg path,
.zl_page_sidebar[title='wallets'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='wallets'] .zl_page_sidebar_link svg path{
    fill: #53B9EA;
}

.zl_page_sidebar[title='securebackup'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='securebackup'] .zl_page_sidebar_link svg path+path{
    fill: #252F47;
}

.zl_page_sidebar[title='portfolio'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='portfolio'] .zl_page_sidebar_link svg path,
.zl_page_sidebar[title='settings'] .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items[title='settings'] .zl_page_sidebar_link svg path{
    stroke: #53B9EA;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link .zl_page_sidebar_notification_dot{
    width: 6px;
    height: 6px;
    display: block;
    background: linear-gradient(180deg, #FF40A5 0%, #E14CD1 100%);
    opacity: 0.8;
    border-radius: 10px;
    box-shadow: 0px 0px 5px #E01066;
    margin-left: auto;
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_toggle_icon{
    position: absolute;
    right: 20px;
    bottom: 69px;
    background: transparent;
    border: 0;
    padding: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: 0;
    transform: rotate(180deg);
}

.zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_toggle_icon{
    transform: rotate(0);
}

.zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link .zl_pagesidebar_text{
    display: block;
}

.zl_page_sidebar_toggle_btn{
    display: block;
    padding: 0;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 44px;
    left: 20px;
    color: #fff;
    background: linear-gradient(224.09deg, rgba(61, 71, 106, 0.45) 0%, rgba(61, 71, 106, 0.49) 98.47%);
    border: 1px solid #7c86a0;
    border-radius: 4px;
    outline: 0 !important;
}

.zl_page_sidebar_toggle_btn svg{
    width: 24px;
    height: 24px;
}

.zl_all_pages_content .zl_page_sidebar[title='login'] ~ .zl_page_sidebar_toggle_btn, .zl_all_pages_content .zl_page_sidebar[title=''] ~ .zl_page_sidebar_toggle_btn{
    display: none;
}

@media (min-width: 992px){
    /* hide show sidebar */
    .zl_page_sidebar.zl_hide_sidebar{
        max-width: 90px;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link svg{
        margin: 0 auto;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link .zl_pagesidebar_text,
    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link .zl_page_sidebar_notification_dot,
    .zl_page_sidebar.zl_hide_sidebar .zl_main_logo,
    .zl_page_sidebar .zl_mini_sidebar_logo,
    .zl_page_sidebar_toggle_btn{
        display: none;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link{
        padding: 25px 12px;
        justify-content: center;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_mini_sidebar_logo{
        display: block;
        margin: 0 auto;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_logo{
        padding: 0;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_logo a{
        padding: 25px 12px;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link:hover{
        background: linear-gradient(224.09deg, rgba(61, 71, 106, 0.45) 0%, rgba(61, 71, 106, 0.49) 98.47%);
    }

    .zl_light_theme_active .zl_page_sidebar.zl_hide_sidebar .zl_page_sidebar_content .zl_page_sidebar_nav .zl_page_sidebar_items .zl_page_sidebar_link:hover{
        background: #EFF0F2;
    }
}

@media (max-width: 991px){
    .zl_page_sidebar{
        left: -100%;
    }

    .zl_page_sidebar.zl_hide_sidebar{
        left: 0;
        max-width: 270px;
    }

    .zl_page_sidebar.zl_hide_sidebar .zl_mini_sidebar_logo,
    .zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_toggle_icon{
        display: none;
    }

    .zl_page_sidebar .zl_page_sidebar_content .zl_page_sidebar_logo{
        padding: 55px 25px 28px;
    }
    .zl_page_sidebar .zl_page_sidebar_content{
        padding-bottom: 50px;
    }
}

@media (max-width: 767px){
    .zl_page_sidebar_toggle_btn{
        left: 15px;
    }
}